@tailwind base;
@tailwind components;
@tailwind utilities;

body,html {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
